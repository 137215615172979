<template>
  <div class="orderDetail">
    <van-nav-bar  left-arrow  :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
                  class="nav" >
      <template #title>
        <p class="title">转赠详情</p>
      </template>


    </van-nav-bar>

    <div class="main-body" v-if="data.result">
      <div class="top">
        <van-image  class="state-icon" :src="require('../../assets/mine/icon_order_finish.png')"/>

        <p   class="state-desc-success">转赠成功</p>

      </div>
      <div class="product-detail">
        <van-image radius="8px"  class="left-img" :src="data.result.image"/>
        <div class="product-detail-right">
          <div class="product-title-top">
            <p class="t-product">{{data.result.productName}}</p>
            <p v-if="data.result.isTransfer == 1" class="goOut">转出</p>
            <p  v-else class="goIn">转入</p>
          </div>
          <div class="div-number">
            <div class="left-chain-logo">
              <van-image :src="require('../../assets/mine/icon_mine_chain.png')" class="goods-bottom-img"  >
              </van-image>
            </div>
            <p class="ntf-number">{{  (data.result.number == null || data.result.number == '') ? '编码生成中' : data.result.number}}</p>
          </div>

        </div>

      </div>

      <div class="order-detail">


        <div class="singleOne">
          <p class="singleOne-left">
            类型
          </p>
          <p class="singleOne-right">
            {{data.result.isTransfer == 2 ? '转入' : '转出'}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
            转赠时间
          </p>
          <p class="singleOne-right">
            {{data.result.transferTime}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
            转出地址
          </p>
          <p class="singleOne-right">
            {{data.result.isTransfer == 2 ? data.result.transferUserHashCode : data.result.userHashCode}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
            受赠地址
          </p>
          <p class="singleOne-right">
            {{data.result.isTransfer == 2 ?data.result.userHashCode : data.result.transferUserHashCode}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
            转赠单号
          </p>
          <p class="singleOne-right">
            {{data.result.orderId}}
          </p>
        </div>

      </div>


    </div>


  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { clearToast, showFailToast, showLoading } from "../../utils/util";
import {getCurrentInstance, reactive} from "vue";
import router from "../../router";
export default {
  name: "tranformDetail",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();

    let id = $router.currentRoute.value.query.id
    let isTransfer = $router.currentRoute.value.query.isTransfer
    const data = reactive({
        id:id,
        isTransfer:isTransfer,
        result:null,
    })

    const loadData = () => {
      showLoading();
      proxy.$http.get("/wenwu-user/order/transfer/info?id="+ data.id + '&isTransfer=' + data.isTransfer).then(res => {
        clearToast()
        if (res.data.code == 200) {

          data.result = res.data.data;
        } else {
          showFailToast(res.data.message)

        }
      }).catch((err)=>{
        clearToast()
        showFailToast(err.message)
      })
    }


    const goBack = (() => {
      $router.back(-1)
    })

    const goToHome = () => {
      $router.push('/index')
    }

    loadData()

    return {
      goBack,
      goToHome,
      data
    };
  }
};
</script>
<style scoped lang="scss" src="./index.scss">

</style>

